import React, { Component } from "react"
import { v4 } from "uuid"
import Wrap from "../../wrap"

import chris from "./assets/chris-small.jpg"
import bartwr from "./assets/bartwr-small.jpg"
import max from "./assets/max_026.jpg"
import cipi from "./assets/anon_cipi.png"
import barv from "./assets/barv-small.jpg"

const TEAM = [
  {
    name: "Chris van Maarseveen",
    role: "General Product Lead",
    description:
      "Diesmaster joined the Komodo Platform team at the age of 16, being their youngest member. He dedicated himself to BarterDEX and developed his vision for future products.",
    image: chris,
  },
  {
    name: "Bart Roorda",
    role: "Tech Lead",
    description:
      "bartwr has a long history of open source advocacy and is a Dutch celeb in community building for blockchain and tech innovation. He’s a full stack developer with over 10 years experience.",
    image: bartwr,
  },
  {
    name: "Max Theyse",
    role: "Design Lead",
    description:
      "Max found the other team members by his passion for Komodo and BarterDEX. He has an inborn need to optimise the user experience for crypto, especially for BarterDEX based apps.",
    image: max,
  },
  {
    name: "Cipi",
    role: "Server Operator Lead",
    description:
      "The most experienced LP node operator there is. Like Diesmaster, cipi is part of the Komodo team and shares the passion for BarterDEX and Komodo with the rest.",
    image: cipi,
  },
  {
    name: "Bart van Maarseveen",
    role: "Operation & Evangelisation Lead",
    description:
      "barv has a history with open source and web entrepreneurship since the mid nineties which makes him our valuable senior. Organises communities and talks with bartwr and loves to innovate & promote.",
    image: barv,
  },
]

class Competitors extends Component {
  render() {
    return (
      <article className="Team text-lg">
        <Wrap maxWidth="max-w-7xl" paddingY="0">
          <h1
            className="h1 h1--coin pt-20 pb-4 mb-0 text-theme-blue-0072bb text-center"
            data-sal-duration="300"
            data-sal="fade"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            Our team
          </h1>
          <div className="flex flex-wrap px-12 sm:px-0 mb-16">
            {TEAM.map(item => (
              <article
                className="Team-member flex flex-wrap sm:flex-no-wrap my-4 lg:w-1/2 md:px-5"
                data-sal-duration="300"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-out-bounce"
                key={v4()}
              >
                <div className="flex justify-center w-full sm:w-auto">
                  <div
                    className="bg-cover mb-6 rounded-xl"
                    style={{
                      width: "200px",
                      height: "220px",
                      backgroundImage: `url("${item.image}")`,
                    }}
                  ></div>
                </div>
                <div className="flex flex-col sm:pl-10 text-center text-center sm:text-left">
                  <h1 className="mb-2 text-3xl font-bold leading-tight text-black">
                    {item.name}
                  </h1>
                  <div className="-mt-2 text-theme-gray-666666 font-bold">
                    {item.role}
                  </div>
                  <p className="my-5 mx-auto text-theme-gray-8c8c8c">
                    {item.description}
                  </p>
                </div>
              </article>
            ))}
          </div>
        </Wrap>
      </article>
    )
  }
}

export default Competitors
