import React, { Component } from "react"
import Wrap from "../../wrap"
import background from "./background.png"
import flowchart from "./revenue-flowchart.png"
import backgroundBottom from "./background-bottom.png"

class RevenueShare extends Component {
  render() {
    return (
      <section className="text-white bg-theme-blue-0072bb">
        <img src={background} alt="Decoration" />
        <Wrap paddingY="0">
          <div
            data-sal-duration="300"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            <div className="sm:px-20">
              <h1 className="h1 h1--coin">We share</h1>
              <p className="max-w-xl text-lg leading-snug">
                <font size="+3">100%</font> of all revenue flows directly to all
                holders of CoinCollect (CC). Various revenue streams make sure
                the coin will generate profitable rewards for CC coin holders.
              </p>
            </div>
          </div>
        </Wrap>
        <img
          className="w-screen max-w-4xl px-4 md:px-16 my-16 mx-auto"
          src={flowchart}
          alt="Revenue flowchart"
          data-sal-duration="300"
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        />
        <img src={backgroundBottom} alt="flowchart" />
      </section>
    )
  }
}

export default RevenueShare
