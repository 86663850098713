import React from "react"
import PropTypes from "prop-types"

import Wrap from "../../wrap"
import CCL from "../../../svg/coins/CCL.inline.svg"

const Quote = ({ title, text }) => (
  <Wrap paddingY="0">
    <article
      data-sal-duration="300"
      data-sal="slide-up"
      data-sal-delay="300"
      data-sal-easing="ease-out-bounce"
    >
      <div className="Mission relative max-w-4xl p-12 sm:p-20 mx-auto mt-20 mb-32 text-center text-theme-blue-0072bb bg-theme-gray-f9f9f9 rounded-uber">
        <h1 className="text-4xl font-bold mb-4">{title}</h1>
        <p className="text-2xl mb-6">{text}</p>
        <div className="absolute bottom-0 w-full -ml-20 -mb-16 flex justify-center">
          <CCL className=" w-32 h-32 bg-white p-5 rounded-full" />
        </div>
      </div>
    </article>
  </Wrap>
)

Quote.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Quote
