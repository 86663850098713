import React, { Component } from "react"
import Wrap from "../../wrap"
import title from "./title.png"
import logoDevsec from "./logo-devsec.png"
import logoSupernet from "./logo-supernet.png"

class Investors extends Component {
  render() {
    return (
      <Wrap maxWidth="max-w-7xl" className="pb-0">
        <article
          className="Investors flex flex-wrap justify-between text-lg"
          data-sal-duration="300"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <div className="w-full md:w-1/2">
            <img
              className="Investors-title w-full"
              src={title}
              alt="Our Advisors & Investors Banner"
            />
          </div>
          <div className="pt-16 md:pt-0 md:w-1/2 md:pl-10">
            <div className="Investors-investor relative mb-32 text-theme-gray-8c8c8c">
              <div className="flex flex-col sm:flex-row sm:justify-between text-center sm:text-left">
                <div className="flex flex-col">
                  <h1 className="h1 h1--coin sm:inline-block leading-none text-theme-blue-0072bb">
                    DEVSEC
                  </h1>
                  <a
                    href="https://devsec.info/"
                    target="_blank"
                    rel="noopener noreferrer noffolow"
                    className="-mt-4 sm:inline-block font-bold no-underline lowercase mb-4 text-theme-blue-40b1f3"
                  >
                    Go to website >
                  </a>
                </div>
                <div className="flex justify-center sm:mb-4">
                  <img
                    src={logoDevsec}
                    alt="DevSec logo"
                    className="md:mt-2 sm:mb-8"
                  />
                </div>
              </div>
              <p className="mb-4">
                We are happy to have DEVSEC as an advisor. With DEVSEC we have
                access to a decentralised team of engineers, developers and
                security experts including JL777 (tech lead Komodo) & CA333
                (security lead Komodo).
              </p>
              <p className="mb-4">
                DEVSEC is the ultimate partner to perform secure code reviews,
                exploitation and pen testing, avoiding exploits and more.
              </p>
            </div>

            <div className="Investors-investor relative mb-32 text-theme-gray-8c8c8c">
              <div className="flex flex-col sm:flex-row sm:justify-between text-center sm:text-left">
                <div className="flex flex-col">
                  <h1 className="h1 sm:inline-block leading-none text-theme-blue-0072bb">
                    SuperNet
                  </h1>
                  <a
                    href="https://supernet.org"
                    target="_blank"
                    rel="noopener noreferrer noffolow"
                    className="-mt-4 sm:inline-block font-bold no-underline lowercase mb-4 text-theme-blue-40b1f3"
                  >
                    Go to website
                  </a>
                </div>
                <div className="flex justify-center sm:mb-4">
                  <img
                    src={logoSupernet}
                    alt="SuperNet logo"
                    className="sm:mb-4"
                  />
                </div>
              </div>
              <p className="mb-4">
                SuperNET is a collaborative community of participating
                cryptocurrency coins and their development teams, alongside
                innovative coders, networking experts, social media and
                commercial development specialists and third party service
                providers, seeking to create a united platform of technologies
                focussing on the establishment.
              </p>
              <p className="mb-4">
                SuperNET is invested in projects like Komodo, Bitcoin, IOTA,
                Syscoin & Stratis for a total of ~ 60M USD.
              </p>
            </div>
          </div>
        </article>
      </Wrap>
    )
  }
}

export default Investors
