import React, { Component } from "react"
import Wrap from "../../wrap"

class Solution extends Component {
  render() {
    return (
      <div className="Solution bg-gradient-solution">
        <Wrap className="flex flex-wrap md:flex-no-wrap pt-16 lg:pt-32">
          <h1
            className="h1 h1--coin pb-8 pl-2 mr-5 text-left md:w-1/3"
            data-sal-duration="300"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            Our solution
          </h1>

          <dl
            className="text-lg pl-16 pr-8 md:w-2/3 md:pt-2"
            data-sal-duration="300"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            <dt>
              <h2 className="h2 leading-tight">
                One platform to buy, manage and trade your coins
              </h2>
            </dt>
            <dd className="mb-20">
              <p className="mb-4">
                We integrate the best fiat gateways to make sure you can buy
                crypto with dollars or euros in CoinCollect.
              </p>
              <p className="mb-4">
                We use cutting edge decentralized technology in CoinCollect to
                ensure that you can easily trade and securely{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Hodl"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="text-white underline"
                >
                  hodl
                </a>{" "}
                in your application.
              </p>
            </dd>
            <dt>
              <h2 className="h2">Your keys, your coins</h2>
            </dt>
            <dd className="mb-20">
              <p className="mb-4">
                In Coincollect we use advanced decentralized exchange technology
                allowing us to make sure you own your keys at all times. This
                means you are in control.
              </p>
              <p className="mb-4">
                Eliminating the problem you had with the first generation (1.0)
                exchanges and their vulnerabilities.
              </p>
            </dd>
            <dt>
              <h2 className="h2">Smooth user experience</h2>
            </dt>
            <dd className="mb-20">
              <p>
                CoinCollect is the most smooth experience there is in crypto.
                Every screen navigates intuitively. Everything is clear as
                daylight.
              </p>
              <p>
                No more frustrating moments in which you could just smash your
                device through a wall.
              </p>
            </dd>
          </dl>
        </Wrap>
      </div>
    )
  }
}

export default Solution
