import React, { Component } from "react"
import Wrap from "../../wrap"

class ProblemsWeSolve extends Component {
  render() {
    return (
      <Wrap paddingY="0">
        <div className="flex flex-wrap py-20 md:px-4 text-theme-blue-0072bb">
          <div
            className="flex-1"
            data-sal-duration="300"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            <h1 className="h1 h1--coin pb-8 mr-5 sm:pt-10">
              Problems that keep people from crypto
            </h1>
          </div>

          <div
            className="flex flex-wrap flex-1 p-12 text-lg bg-theme-gray-f9f9f9 text-theme-gray-767676 rounded-uber"
            data-sal-duration="300"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            <section className="mb-24">
              <h2 className="h2 pb-1 text-theme-blue-0072bb">
                Fears to get hacked
              </h2>

              <p className="mb-4">
                <i>Fear your bitcoins will get hacked on exchanges?</i>
              </p>
              <p>
                People have the feeling there is a lot of money to gain in
                crypto, but also a lot to lose. This fear is keeping them from
                investing.
              </p>
            </section>

            <section className="mb-24">
              <h2 className="h2 pb-1 text-theme-blue-0072bb">
                Drowned in accounts
              </h2>
              <p className="mb-4">
                <i>
                  Sign up to three different sites and each time submitting your
                  ID?
                </i>
              </p>
              <p>
                This is a huge barrier that keeps users from entering crypto.
              </p>
            </section>

            <section className="mb-24">
              <h2 className="h2 pb-1 text-theme-blue-0072bb">
                Horrible interfaces
              </h2>
              <p className="mb-4">
                Finally signed up, you are dazzled by all the complex options.
              </p>
              <p>
                All exchanges are built for people acting as professional
                traders. Most people just want to buy and sell quick and easy.
                Current complexity is keeping them from trading.
              </p>
            </section>
          </div>
        </div>
      </Wrap>
    )
  }
}

export default ProblemsWeSolve
