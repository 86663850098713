import React, { Component } from "react"
import Wrap from "../../wrap"

class Intro extends Component {
  render() {
    return (
      <section className="p-5 lg:px-0 lg:py-32 text-black">
        <Wrap paddingY="0">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div
              data-description="Page left (desktop)"
              data-sal-duration="300"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            >
              <h1 className="CoinPage-title mb-2 font-bold text-theme-gray-333333">
                Get CC, get revenue
              </h1>

              <h2 className="CoinPage-subtitle font-normal">
                Our unique coin structure is beneficial for all.
              </h2>

              <div className="hidden lg:block mt-10 lg:mt-20">
                <a
                  href="https://coincollect.us19.list-manage.com/subscribe?u=fea973e7599186bcdb671c103&id=fe1018d331"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button darkblue big no-underline mr-5"
                >
                  Sign up for our newsletter
                </a>
              </div>
            </div>

            <div
              className="mt-5 lg:mt-0 text-left lg:text-right text-lg"
              data-description="Page right (desktop)"
              data-sal-duration="300"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            >
              <div className="mb-4">
                <b className="font-bold text-black">200.000.000</b> max. supply
              </div>
              <div className="mb-4">
                <b className="font-bold text-black">0.0001</b> transaction fee
              </div>
              <div className="mb-4">
                <b className="font-bold color-darkblue">Komodo</b> assetchain
              </div>
              <div className="mb-4">Secured with Bitcoin's hashrate</div>
            </div>
          </div>
        </Wrap>
      </section>
    )
  }
}

export default Intro
