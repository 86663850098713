import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Intro from "../components/coin/Intro/Intro"
import Investors from "../components/coin/Investors/Investors.js"
import Competitors from "../components/coin/Competitors/Competitors.js"
import CtaWrapper from "../components/coin/CtaWrapper/CtaWrapper.js"
import Quote from "../components/coin/Quote/Quote"
import ProblemsWeSolve from "../components/coin/ProblemsWeSolve/ProblemsWeSolve.js"
import RevenueShare from "../components/coin/RevenueShare/RevenueShare.js"
import Solution from "../components/coin/Solution/Solution.js"
import Team from "../components/coin/Team/Team.js"
import Footer from "../components/footer"

const CoinPage = () => (
  <Layout>
    <SEO
      title="Get CC, get revenue"
      description="Our unique coin structure is beneficial for all."
    />

    <Intro />
    <RevenueShare />
    <ProblemsWeSolve />

    <Quote
      title="Our mission."
      text="We aim to get everyone on board of crypto. For this we will make
          crypto easy to buy, trade and manage. CoinCollect permits anyone to
          secure their position in crypto and maintain full control over their
          coins."
    />
    <CtaWrapper />
    <Solution />
    <Competitors />
    <Team />
    <Investors />

    <Quote
      title="Our vision."
      text="We will all be paying with our broad coin and token collections in
              the future. Any coin to any coin. Smart wallets will exchange on
              the spot when needed. CoinCollect believes that this process
              should be trustless, decentralized, privacy focussed and easy to
              use."
    />

    <Footer />

  </Layout>
)

export default CoinPage
