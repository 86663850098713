import React, { Component } from "react"
import Wrap from "../../wrap"
import table from "./table.png"

class Competitors extends Component {
  render() {
    return (
      <article className="Competitors text-center ">
        <Wrap paddingY="0">
          <div
            data-sal-duration="300"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-bounce"
          >
            <h1 className="h1 h1--coin pt-20 pb-4 mb-0 text-theme-blue-0072bb">
              CoinCollect versus normal exchanges
            </h1>
            <a href={table}>
              <img
                src={table}
                alt="Table with CoinCollect versus competitors"
                className="max-w-full my-12"
              />
            </a>
          </div>
        </Wrap>
      </article>
    )
  }
}

export default Competitors
