import React from "react"
import Wrap from "../../wrap"

const CtaWrapper = () => {
  return (
    <Wrap paddingY="0">
      <div
        className="flex flex-col md:flex-row md:justify-center m-auto max-w-sm md:max-w-2xl mb-20"
        data-sal-duration="300"
        data-sal="fade"
        data-sal-delay="300"
        data-sal-easing="ease-out-bounce"
      >
        <a
          href="https://coincollect.us19.list-manage.com/subscribe?u=fea973e7599186bcdb671c103&id=fe1018d331"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="button mx-2 mb-4 darkblue big no-underline"
        >
          Subscribe to our newsletter
        </a>
        <a
          href="https://discord.gg/tYvguCv"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="button mx-2 darkblue big no-underline"
        >
          Join our Discord channel
        </a>
      </div>
    </Wrap>
  )
}

export default CtaWrapper
